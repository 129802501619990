import React from "react";
import styled from "styled-components";

export default function Facebook() {
  return (
    <Div>
      <h4>Facebook page</h4>
    </Div>
  );
}

const Div = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
